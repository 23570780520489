import { graphql } from 'gatsby'
import Container from "../../components/container"
import EventTeaser from "./teaser"
import React, { useState } from "react"
import tw from "twin.macro"

const isHomePage = false
const location = {
  path: '/events',
}

const EventList = ({ data }) => {

  const [showAll, setShowAll] = useState(true)

  return (
    <>
      <Container>
        <div tw="mb-10 flex items-end  justify-between lg:mb-18">
          <h1 tw="text-3xl font-bold lg:text-5xl">Kalender</h1>
          {/* <Button>Filter</Button> */}
        </div>
        <ul tw="mb-11 border-b-3 border-black">
          {data.events.edges &&
            data.events.edges.map( ({event}, index) => (
              <li
                tw="border-t-3 border-black py-14 md:px-6 lg:px-11"
                css={[showAll || index < 3 ? tw`block` : tw`hidden`]}
                key={`event-${event.id}`}
              >
                <EventTeaser {...event} />
              </li>
            ))}
        </ul>
        {/**<button
          onClick={() => setShowAll(!showAll)}
          tw="cursor-pointer bg-lilac-500 px-7 py-3 text-xl font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
        >
          {showAll ? `Weniger` : `Mehr`}
        </button>**/}
      </Container>
    </>
  )
}

export default EventList

export const query = graphql`
  query WpEvents {
    events: allWpEvent(sort: { fields: [date], order: DESC }) {
      edges {
        event: node {
          id
          uri
          content
          excerpt
          title
          slug
          startDate
          eventsCategories {
            nodes {
              name
            }
          }
          protagonists {
            moderation {
              ... on WpProtagonist {
                id
                title
                featuredImage {
                  node {
                    id
                    title
                    filename
                    slug
                    uri
                  }
                }
              }
            }
            together {
              ... on WpProtagonist {
                id
                title
                featuredImage {
                  node {
                    id
                    title
                    filename
                    slug
                    uri
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
